* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0 1em;
  font-size: 16px;
  color: #c8d6e5;
  font-family: "Roboto Mono", monospace;
  background-color: #222f3e;
}

button,
input[type="submit"] {
  display: block;
  height: 100%;
  padding: 0.5em 0.75em;
  font-size: 1em;
  font-weight: 700;
  color: #222f3e;
  text-align: center;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 0.25em;
  cursor: pointer;
}

.App {
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 1em 1em 3em;
  margin: 0 auto;
}
header {
  margin: 0 auto 2rem;
}
.stats {
  display: grid;
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
}
.stats .totalPlayers,
.stats .totalPoints {
  display: grid;
  grid-gap: 0.5em;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr auto;
}
.stats .label {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.stats .value {
  color: #fff;
  font-size: 2em;
}

.stopwatch {
  display: grid;
  grid-gap: 0.5em;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr auto auto;
}
.stopwatch p {
  color: #fff;
  font-size: 2em;
  margin: 0 auto;
}
.stopwatch span {
  opacity: 0.05;
}
.stopwatch button.start {
  background-color: #10ac84;
}
.stopwatch button.reset {
  background-color: #ff9f43;
}
.players {
  margin: 0 auto;
}
.player {
  display: grid;
  grid-gap: 0.5em;
  padding: 0.25em;
  align-items: stretch;
  justify-content: center;
  grid-template-columns: auto auto 1fr auto;
  background-color: rgba(255, 255, 255, 0.01);
}
.player:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.025);
}
.player:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.player:last-of-type {
  margin-bottom: 2em;
}

.player button.drop {
  display: none;
  background-color: #ee5253;
}
.player:hover button.drop {
  display: block;
}
.player .crown {
  display: flex;
  align-items: center;
}
.player .crown svg {
  width: 40px;
  fill: rgba(0, 0, 0, 0.1);
}
.player .crown.active svg {
  fill: #feca57;
}
.player .name {
  text-align: left;
}

.player .counter {
  display: grid;
  grid-gap: 0.5em;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr auto auto;
}
.score {
  padding: 0 0.5em;
  color: #fff;
  font-size: 2em;
  text-align: center;
}
button.dec {
  background-color: #ff9f43;
}
button.inc {
  background-color: #10ac84;
}

.add-player {
  display: grid;
  grid-gap: 0.5em;
  align-items: stretch;
  justify-content: center;
  grid-template-columns: 1fr auto;
  padding: 0 0.25em;
}
.add-player input[type="text"] {
  width: 100%;
  padding: 0.5em 0.75em;
  color: #fff;
  background-color: transparent;
  border: 1px solid #576574;
  border-radius: 0.25em;
  font-size: 1em;
}
.add-player input[type="submit"] {
  background-color: #10ac84;
}
